import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  styled,
  Dialog,
  Autocomplete,
  Stack,
  Box
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  ProgressButton,
  formatUtcDateDayjs,
  globalDateFormat,
  useToast
} from "@qubit/autoparts";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppSelector } from "~/app/store";

import { createProductSearchOptions } from "~/config/searchProductConfig";
import { AutostoreBin } from "~/features/autostoreBin";

import { useDebounce } from "~/hooks/useDebounce";

import { useBarcodeScanner } from "~/lib/barCodeScan";
import {
  checkIsExpiration,
  inventoryDateLabel,
  isExpirationValid,
  searchProduct,
  ternaryIff
} from "~/lib/helpers";

import {
  getVariantByVariantId,
  clearSelectedVariant
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";
import { MeasuredValueDto } from "~/types/api";

import QuantityCircle from "./QuantityCircle";

type ProductResult = {
  type: string;
  variantId: Guid;
  displayText: string;
};

const Root = styled(Dialog)(() => ({
  padding: 0,
  margin: 0,
  fontWeight: 400,
  fontFamily: "Roboto"
}));

const mapStateToProps1 = (state: StoreState) => ({
  clientId: getUserClientId(state.login.profile),
  selectedVariant: state.inventory.selectedVariant
});

const connector = connect(mapStateToProps1, {
  getVariantByVariantId,
  clearSelectedVariant
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type AutostorePutawayModalProps = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg";
  browserWidth: number;
  binId: number | null;
  onPutawayButtonClick: (() => Promise<void>) | (() => void);
  changeQuantityFunc: (quantity: number) => void;
  inventoryDate: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  numberOfRows?: number;
  numberOfColumns?: number;
  selectedCompartment?: number;
  emptyCompartments?: number[];
  inv_inventoryDateLabel: string;
  inv_inventoryDateRequired: boolean;
  decantRate?: MeasuredValueDto;
  remaining?: MeasuredValueDto;
  isExpirationRequired?: boolean;
  showProductSearch?: boolean;
  binLoading?: boolean;
  setSelectedCompartment?: (compartmentNumber: number) => void;
  /** if true, will show Cancel button and disable close by clicking out of modal */
  showCancelButton?: boolean;
};

export function AutostorePutawayModalComponent(
  props: AutostorePutawayModalProps
) {
  const { t } = useTranslation();
  const inductionStrategy = useAppSelector(
    (state) =>
      state.store.usersFulfillmentCenter?.clientConfiguration?.inductionStrategy
  );
  const {
    open,
    showCancelButton = false,
    onClose,
    maxWidth,
    browserWidth,
    binId,
    onPutawayButtonClick,
    changeQuantityFunc,
    inventoryDate,
    numberOfRows,
    numberOfColumns,
    selectedCompartment,
    emptyCompartments,
    inv_inventoryDateLabel,
    onDateChange,
    inv_inventoryDateRequired,
    decantRate,
    remaining,
    isExpirationRequired,
    clientId,
    showProductSearch,
    binLoading = false,
    setSelectedCompartment,
    clearSelectedVariant,
    selectedVariant
  } = props;

  const { errorToast } = useToast();
  const quantityMaxValue = 10000;
  const [localQuantityValue, setLocalQuantityValue] = useState(0);
  const [wasClicked, setWasClicked] = useState(false);
  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    ProductResult[]
  >([] as ProductResult[]);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  useEffect(() => {
    if (!selectedVariant) {
      setAutocompleteValue("");
    } else {
      setAutocompleteValue(selectedVariant.productName);
    }

    // clear user selected or shelf life date when the selected variant changes
    onDateChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  // if selectedVariant has shelfLife and user hasn't set inventoryDate, set inventoryDate to today + shelfLife
  useEffect(() => {
    if (selectedVariant?.shelfLife && !inventoryDate) {
      const newDate = dayjs().add(selectedVariant.shelfLife, "days");
      onDateChange(newDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDate, selectedVariant]);

  const selectedOption =
    autocompleteOptions.find(
      (option) => option.variantId === selectedVariant?.variantId
    ) || null;

  const disableButtonForProductSearch =
    binLoading || (showProductSearch && !selectedVariant);

  const autocompleteSearchProducts = useCallback(
    async (input: string | null): Promise<void> => {
      if (!input) {
        clearSelectedVariant();
        return;
      }
      if (input.length) {
        try {
          const hits = clientId ? await searchProduct(input) : [];

          const hitsAsAutocompleteRecords = createProductSearchOptions({
            hits
          });
          updateAutocompleteOptions(hitsAsAutocompleteRecords);
        } catch {
          errorToast("Could not get product list");
        }
      }
    },
    [clearSelectedVariant, clientId, errorToast]
  );

  const searchValue = useDebounce(autocompleteValue, 300, autocompleteValue);
  useEffect(() => {
    if (searchValue?.length) void autocompleteSearchProducts(searchValue);
  }, [searchValue, autocompleteSearchProducts]);

  // if we dont pass in a remaining prop, set it to the max quantity
  const remainingValue = remaining?.value || quantityMaxValue;
  const decantRateValue = decantRate?.value || 1;

  const formattedExpDate =
    !dayjs.isDayjs(inventoryDate) && inventoryDate !== null
      ? dayjs(inventoryDate)
      : inventoryDate;

  useEffect(() => {
    changeQuantityFunc(localQuantityValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuantityValue]);

  useEffect(() => {
    setWasClicked(false);

    const initialQtyValue =
      !inductionStrategy?.includes("ContentCodes") &&
      decantRateValue < remainingValue
        ? decantRateValue
        : remaining?.value || 1;
    setLocalQuantityValue(initialQtyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decantRateValue, remainingValue, binId]);
  // anytime a user changes the form values, reset the wasClicked state to ensure putaway button is enabled
  useEffect(() => {
    if (wasClicked) setWasClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, localQuantityValue, inventoryDate]);

  useBarcodeScanner({
    disabled: !open,
    findScanMatch: async (buffer: string) => {
      const hits = clientId ? await searchProduct(buffer) : [];

      const hitsAsAutocompleteRecords = createProductSearchOptions({
        hits
      });

      if (hits.length > 0) {
        updateAutocompleteOptions(hitsAsAutocompleteRecords);
      }
      return hitsAsAutocompleteRecords?.[0] ?? null;
    },
    processScanMatch: (product) => {
      if (product) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.getVariantByVariantId(product.variantId);
      }
    }
  });

  const handlePutawayClick = () => {
    if (wasClicked) return;
    setWasClicked(true);

    if (remaining && localQuantityValue > remainingValue) {
      errorToast(t("max quantity for selected item is") + ` ${remainingValue}`);
    } else if (localQuantityValue === 0) {
      setLocalQuantityValue(0);
      errorToast(`Quantity must be greater than zero`);
    } else {
      setLocalQuantityValue(localQuantityValue);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      onPutawayButtonClick();
    }
  };

  /** if dateConfig is expiration, check to make sure expiration date is in the future */
  const isPastDate =
    checkIsExpiration(inv_inventoryDateLabel) &&
    formattedExpDate?.isBefore(dayjs().startOf("day").add(1, "day"));

  const isDateInvalid =
    !isExpirationValid(
      inv_inventoryDateRequired ||
        isExpirationRequired ||
        selectedVariant?.isExpirationRequired,
      formattedExpDate
    ) || isPastDate;

  const inventoryDatePicker = (
    <DesktopDatePicker
      slotProps={{
        textField: {
          InputLabelProps: {
            shrink: true
          },
          sx: {
            ".MuiInputBase-input": {
              fontSize: "2.25rem"
            }
          },
          label: t(inventoryDateLabel(inv_inventoryDateLabel)),
          variant: "outlined",
          fullWidth: true,
          error: isDateInvalid
        }
      }}
      label={t(inventoryDateLabel(inv_inventoryDateLabel))}
      value={inventoryDate ? dayjs(formatUtcDateDayjs(inventoryDate)) : null}
      format={globalDateFormat}
      onChange={onDateChange}
      minDate={checkIsExpiration(inv_inventoryDateLabel) ? dayjs() : undefined}
    />
  );

  return (
    <Root
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={
        showCancelButton
          ? undefined
          : () => {
              onClose();
              setAutocompleteValue("");
              setLocalQuantityValue(0);
            }
      }
    >
      <DialogTitle>
        <Typography
          sx={{
            fontSize: "24px"
          }}
        >
          {t("add inventory")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack p={3} gap={3}>
          <Stack flexDirection="row" justifyContent="center" gap={3}>
            <Stack alignItems="center" flexGrow={1} gap={1}>
              <Typography variant="h5">
                {t("bin")} {binId || undefined}
              </Typography>
              <AutostoreBin
                state={binLoading ? "Waiting for Bin" : "Bin Opened"}
                pickQuantity={localQuantityValue}
                pickCompartment={selectedCompartment || 0}
                numberOfRows={numberOfRows || 1}
                numberOfColumns={numberOfColumns || 1}
                binId={binId || undefined}
                hideBinId
                emptyCompartments={emptyCompartments}
                setSelectedCompartmentCallback={
                  setSelectedCompartment
                    ? (num) => setSelectedCompartment(num)
                    : undefined
                }
              />
            </Stack>
            <Stack gap={3} flexBasis={600}>
              {showProductSearch && (
                <Autocomplete<ProductResult>
                  options={autocompleteOptions}
                  filterOptions={(ops) => ops}
                  getOptionLabel={(option) => option.displayText}
                  onChange={(_e, option) => {
                    if (!option) {
                      props.clearSelectedVariant();
                      return;
                    }
                    void props.getVariantByVariantId(option?.variantId);
                    setWasClicked(false);
                  }}
                  value={selectedOption}
                  onInputChange={(_e, inputValue) => {
                    setAutocompleteValue(inputValue);
                  }}
                  inputValue={autocompleteValue}
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      type="text"
                      label={t("search placeholder")}
                      error={!selectedVariant}
                    />
                  )}
                />
              )}
              <Box position="relative">
                <TextField
                  label={t("quantity")}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    sx: {
                      py: 3,
                      fontSize: "5em",
                      textAlign: "center"
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "darkGray.main",
                      fontSize: 22
                    }
                  }}
                  value={localQuantityValue}
                  onChange={(e): void => {
                    const inputValue = parseInt(e.target.value || "0", 10);
                    if (
                      inputValue <= remainingValue &&
                      inputValue <= quantityMaxValue
                    ) {
                      setLocalQuantityValue(inputValue);
                    } else if (inputValue > remainingValue) {
                      errorToast(
                        t("max quantity for selected item is") +
                          ` ${remainingValue}`
                      );
                    }
                  }}
                />
                <Stack
                  sx={{
                    px: 2,
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)",
                    width: "auto"
                  }}
                >
                  <QuantityCircle
                    quantity={<ExpandLessOutlined />}
                    variant="circle"
                    color="#000"
                    backgroundColor="#fff"
                    border="2px solid black"
                    height={
                      browserWidth >= 1280
                        ? 60
                        : ternaryIff(browserWidth >= 960, 40, 30)
                    }
                    width={
                      browserWidth >= 1280
                        ? 60
                        : ternaryIff(browserWidth >= 960, 40, 30)
                    }
                    onClickCallback={() => {
                      if (localQuantityValue + 1 <= remainingValue) {
                        setLocalQuantityValue(localQuantityValue + 1);
                      } else if (remaining) {
                        errorToast(
                          t("max quantity for selected item is") +
                            ` ${remainingValue}`
                        );
                      }
                    }}
                  />
                  <QuantityCircle
                    quantity={<ExpandMoreOutlined />}
                    variant="circle"
                    color="#000"
                    backgroundColor="#fff"
                    border="2px solid black"
                    height={
                      browserWidth >= 1280
                        ? 60
                        : ternaryIff(browserWidth >= 960, 40, 30)
                    }
                    width={
                      browserWidth >= 1280
                        ? 60
                        : ternaryIff(browserWidth >= 960, 40, 30)
                    }
                    onClickCallback={() => {
                      if (localQuantityValue - 1 > 0) {
                        setLocalQuantityValue(localQuantityValue - 1);
                      } else {
                        errorToast(`Quantity must be greater than zero`);
                      }
                    }}
                  />
                </Stack>
              </Box>
              {inventoryDatePicker}
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={3}>
            {showCancelButton && (
              <ProgressButton
                buttonSize="xLarge"
                emphasis="high"
                responsive
                variant="contained"
                color="secondary"
                fullWidth
                style={{ flexGrow: 1 }}
                startIcon={<CloseIcon style={{ fontSize: 22 }} />}
                onClick={() => {
                  onClose();
                }}
              >
                {t("cancel")}
              </ProgressButton>
            )}
            <ProgressButton
              buttonSize="xLarge"
              emphasis="high"
              responsive
              variant="contained"
              color="primary"
              fullWidth
              style={{ flexGrow: 2 }}
              startIcon={<SystemUpdateAltIcon style={{ fontSize: 22 }} />}
              onClick={() => {
                handlePutawayClick();
              }}
              disabled={
                !localQuantityValue ||
                wasClicked ||
                isDateInvalid ||
                disableButtonForProductSearch
              }
            >
              {t("put away")}
            </ProgressButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Root>
  );
}

export const AutostorePutawayModal = connector(AutostorePutawayModalComponent);
